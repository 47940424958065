'use client';

import React from 'react';
import Link from 'next/link';
import { usePathname } from 'next/navigation';

import { useGlobalContext } from '~/global/Contexts/Global.context';

import { ROUTES, isEngageRoutesType } from '~/checkout/Types/constants';

export const ReturnToBillingPaymentButton = () => {
	const { magicModal } = useGlobalContext();
	const pathname = usePathname();

	if (isEngageRoutesType(ROUTES)) {
		return null;
	}
	if (pathname && pathname.startsWith('/checkout')) {
		return (
			<Link
				className="Button tw-mt-10 tw-mb-4"
				href={ROUTES.CHECKOUT_FLOW.BILLING_PAYMENT.pathname}
				onClick={magicModal.closeModal}
			>
				Return to Billing & Payment
			</Link>
		);
	}
	return null;
};
