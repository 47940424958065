'use client';

import React from 'react';
import { observer } from 'mobx-react';
import dynamic from 'next/dynamic';

import { type AccountExistsCheckoutErrorMessageProps, AccountExistsCheckoutErrorMessage } from '~/util/messaging/promise-error/AccountExistsCheckoutErrorMessage';

const ToolbarStoreProvider = dynamic(
	() => import('~/engage/toolbar/Contexts/EngageToolbar.context').then(mod => mod.ToolbarStoreProvider),
);

export const UsernameUniqueConstraintErrorMessage = observer((props: AccountExistsCheckoutErrorMessageProps) => {
	return (
		<ToolbarStoreProvider>
			<AccountExistsCheckoutErrorMessage {...props} />
		</ToolbarStoreProvider>
	);
});
