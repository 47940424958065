import React, { useEffect } from 'react';
import Script from 'next/script';
import { observer } from 'mobx-react';

import { adobeLaunchGlobalUrlSDK } from '~/global/global.constants';
import { useGlobalContext } from '~/global/Contexts/Global.context';
import { IPersonalizationEvent, IPersonalizationModalEvent } from '~/personalization/Personalization.types';
import { PersonalizationDecisionsModel } from '~/personalization/Models/PersonalizationDecisions.model';
import { triggerPersonalizationModal } from '~/personalization/Personalization.utils';

export const AdobeTracking = observer(() => {
	const {
		personalizationStore = {},
	} = useGlobalContext();

	const handlePersonalizationDecision = (event: Event) => {
		const { detail } = event as CustomEvent<IPersonalizationEvent>;

		personalizationStore.setDecisionsModel(new PersonalizationDecisionsModel({ decisions: detail }));
	};

	const handlePersonalizationModal = (event: Event) => {
		const {
			detail: {
				modalId,
			},
		} = event as CustomEvent<IPersonalizationModalEvent>;

		triggerPersonalizationModal({ modalId });
	};

	useEffect(() => {
		window.addEventListener('personalization', event => handlePersonalizationDecision(event));

		window.addEventListener('personalizationModal', event => handlePersonalizationModal(event));

		return () => {
			window.removeEventListener('personalization', handlePersonalizationDecision);

			window.removeEventListener('personalizationModal', handlePersonalizationModal);
		};
	}, []);

	if (!adobeLaunchGlobalUrlSDK) {
		return null;
	}

	return (
		<>
			<Script
				strategy="afterInteractive"
				src={adobeLaunchGlobalUrlSDK}
			/>
		</>
	);
});
