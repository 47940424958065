import { ComponentType, useEffect, useState } from 'react';

export const withDynamicHook = <TProps extends Record<string, any>>(
	hookName: string,
	importFn: Function,
	Component: ComponentType<TProps>,
) => {
	return (props: TProps) => {
		const [hook, setHook] = useState();

		useEffect(() => {
			importFn().then((mod: any) => setHook(() => mod[hookName]));
		}, []);

		if (!hook) {
			return null;
		}
		const newProps = { ...props, [hookName]: hook };

		return <Component {...newProps} />;
	};
};
