import React from 'react';
import cn from 'classnames';

export const NonDeliverableCertificateOfInsuranceErrorMessage = ({ inline = false }: { inline?: boolean }) => {
	return (
		<div className={cn({ 'tw-text-red': inline })}>
			An error has occurred. For help, please contact Customer Care at <a href="tel:8003019720">800.301.9720</a>.
		</div>
	);
};
