import { PERSONALIZATION_SCOPE } from '~/personalization/Personalization.types';

export const triggerPersonalizationModal = ({ modalId = '' }) => {
	const event = new CustomEvent(
		'personalization',
		{
			detail: {
				propositions: [
					{
						scope: PERSONALIZATION_SCOPE.MODAL,
						scopeDetails: {
							correlationID: '000000:0:0',
						},
						items: [
							{
								data: {
									content: {
										component: modalId,
									},
								},
							},
						],
						mockDecision: true,
					},
				],
			},
		},
	);

	window.dispatchEvent(event);
};
